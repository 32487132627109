/* Import Google Fonts */
@import url('https://fonts.cdnfonts.com/css/butter-haunted');
@import url('https://fonts.cdnfonts.com/css/poppins');

/* General Body Styling */
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Header Styling */
.mission_header {
    font-size: 50px;
    text-align: center;
    font-weight: 900;
    color: rgb(88, 10, 161);
    padding-top: 50px;
    font-family: 'Poppins', sans-serif;
}

.mission_paragraph {
    text-align: center;
    font-size: 25px;
    line-height: 1.6;
    margin: 20px 10px;
    color: #333;
}

/* Horizontal Line Styling */
.hrtag {
    height: 5px;
    border-width: 0;
    width: 20%;
    color: rgb(198, 169, 6);
    background-color: rgb(198, 169, 6);
    margin-left: 40%;
}

/* Card Styling */
.card {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card1,
.card2 {
    background-size: cover;
    background-position: center;
    position: relative;
}

.card-body1,
.card-body2 {
    border-radius: 20px;
    padding: 20px;
    position: relative;
}

.card-title {
    margin-top: 50px;
    margin-bottom: 50px;
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
}

/* Specific card colors */
.card1 .card-body1 {
    background-color: rgba(88, 10, 161, 0.8);
}

.card2 .card-body2 {
    background-color: rgba(235, 196, 20, 0.8);
}

/* Desktop Large Screens (min-width: 1000px) */
@media screen and (min-width: 1000px) {
    .mission_header {
        font-size: 40px;
    }

    .mission_paragraph {
        font-size: 40px;
    }

    .hrtag {
        width: 20%;
        margin-left: 40%;
    }

    .card1 {
        background-image: url('../../IMG/mental2.png');
    }

    .card2 {
        background-image: url('../../IMG/mental2.png');
    }

    .card-title {
        font-size: 20px;
    }
}

/* Tablet and Mobile Screens (max-width: 999px) */
@media screen and (max-width: 1100px) {
    .mission_header {
        font-size: 40px;
        padding-top: 40px;
    }

    .mission_paragraph {
        font-size: 30px;
    }

    .hrtag {
        width: 25%;
        margin-left: 37%;
        background-color: rgb(239, 242, 93);
    }

    .card1 {
        background-image: url('../../IMG/mental.jpeg');
    }

    .card2 {
        background-image: url('../../IMG/mental.jpeg');
    }

    .card-title {
        font-size: 18px;
        margin-top: 60px;
    }
}

/* Small Mobile Screens (max-width: 480px) */
@media screen and (max-width: 480px) {
    .mission_header {
        margin-top: 100px;
        font-size: 50px !important; /* Added !important */
        font-weight: 900 !important;
    }

    .mission_paragraph {
        font-size: 30px !important; /* Added !important */
        padding: 0 15px;
    }

    .hrtag {
        width: 30%;
        margin-left: 35%;
    }

    .card-title {
        font-size: 30px !important; /* Added !important */
        margin-top: 40px;
    }

    .card-body1,
    .card-body2 {
        padding: 15px;
    }

    .card {
        margin: 10px 0;
    }

    .card1, .card2 {
        background-size: contain;
        background-position: top;
    }
}
