.Abt1 {
    font-size: 30px;
    font-weight: 900;
    padding-top: 20px;
    color: rgb(88, 10, 161);
}
.Abt2 {
    font-size: 25px;
    color: rgb(124, 119, 119);
}
.Abtimg1 {
    width: 100%;
    height: 100%;
}

.quote-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust height as needed */
    position: relative;
    background-attachment: fixed;
    margin-bottom: 150px;
  }
  
  .quote-container {
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 20px 40px;
    margin-top: 15%;
    margin-bottom: -10;
    max-width: 800px;
    text-align: center;
  }
  
  .quote-text {
    font-size: 24px;
    font-weight: 400;
    color: #e63946; /* Adjust to match the red color */
    margin-bottom: 20px;
  }
  
  .quote-author {
    font-size: 16px;
    font-weight: bold;
    color: #333; /* Adjust text color */
  }
  
  .quote-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Dark overlay */
    z-index: -1;
  }

  
@media screen and (max-width: 1024px) {
    .Abt1 {
        font-size: 50px !important;
        font-weight: 900;
        padding-top: 20px;
        color: rgb(88, 10, 161);
    }
    .Abt2 {
        font-size: 35px !important;
        color: rgb(124, 119, 119);
    }
    .Abtimg1 {
        width: 100%;
        height: 80%;
    }
}