/* General Navbar Styles */
.navbar {
    background-color: transparent !important; /* Transparent background */
    padding: 1rem 20px;
  }
  
  /* Navbar links text color */
  .navbar-dark .navbar-nav .nav-link {
    color: black !important; /* Black text */
  }
  
  /* Navbar-toggler icon color to black */
  .navbar-toggler-icon {
    background-color: black !important; /* Black color for the hamburger icon */
  }
  
  /* Make the hr element black */
  hr {
    border: 1px solid black; /* Black color */
    margin: 20px 0; /* Optional: Adds space above and below the hr */
  }
  
  /* Logo styling */
  .navbar-brand {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    width: 100%;
  }
  
  /* Mobile Screen: Logo aligned left */
  @media (max-width: 991px) {
    .navbar-brand {
      text-align: left;
      width: auto;
    }
  }
  
  /* Sticky Navbar */
  .fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
  }
  
  /* Navbar link hover effect */
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #f0a500 !important; /* Hover color */
  }
  