.blog-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.blog-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.blog-card:hover {
  transform: scale(1.05);
}

.blog-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-content {
  padding: 15px;
}

.desktop-title {
  font-size: 26px;
  font-weight: bold;
}

.desktop-subtitle {
  font-size: 18px;
}

.mobile-blog-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.mobile-blog-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.mobile-blog-content {
  padding: 10px;
}

.mobile-title {
  font-size: 22px;
  font-weight: bold;
}

.mobile-subtitle {
  font-size: 18px;
  font-weight: normal;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: 2rem;
  filter: invert(1);
}
.btn {
  width: 200px;
}
/* Media Query for Mobile */
@media screen and (max-width: 900px) {
  .ptitle {
    font-size: 40px !important;
    font-weight: bold;
  }
  .desktop-subtitle, .mobile-subtitle {
    font-size: 34px !important;
  }
  .btn {
    width: 400px;
    height: 100px;
    font-size: 40px;
  }
}
