.foot {
  background: rgb(88, 10, 161);
  font-family: 'Sanchez', serif;
}

#button-addon1 {
  color: #ffc371;
}

i {
  color: #0000cc;
  font-size: 20px;
}

.form-control::placeholder {
  font-size: 30px;
  color: #aaa;
  font-style: italic;
}

.form-control.shadow-0:focus {
  box-shadow: none;
}

.display-3 {
  font-family: 'Sanchez', serif;
}

p {
  font-family: 'Tajawal', sans-serif;
}

.lead {
  /* font-family: 'Sanchez 200', serif; */
  padding-left: 60px;
  padding-right: 60px;
  font-size: 30px;
}

.formthree {
  background: transparent;
}

.ptb-100 {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.formthree .form-control {
  border-top: 10px;
  border-left: 0;
  border-right: 0;
  font-size: 13px;
  border-bottom: 1px solid #d3d3d3;
  background: transparent;
  font-family: 'Roboto';
}

form .btn {
  border-radius: 0 !important;
  background-color: gold;
}

.btnprim {
  background-color: gold;
  margin-left: 30%;
  font-size: 18px;
  font-weight: bolder;
  text-align: center;
  display: block;
  margin: 20px auto;
}

.formthree form button.btn {
  margin: 30px auto;
  display: block;
  padding: 11px 32px;
  background: #4285f4;
  border: 1px solid #4285f4;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.formthree .btn-primary.focus, .formthree .btn-primary:hover {
  background-color: transparent;
  color: #4285f4;
  border: 1px solid #4285f4 !important;
}

/* Footer Social Icons */
.social-icons {
  margin-top: 60px;
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  transition: transform 0.3s ease-in-out;
}

.social-link:hover {
  transform: scale(1.1);
}

/* Specific Background Colors */
.facebook { background-color: #1877F2; }
.twitter { background-color: #1DA1F2; }
.google { background-color: #DB4437; }
.instagram { background-color: #E1306C; }
.linkedin { background-color: #0077B5; }
.github { background-color: #333; }

/* Copyright Text */
.bg-light {
  padding: 15px 0;
}



@media screen and (max-width: 999px) {
  .foot_text {
      font-size: 30px;
  }

  .lead1 {
    font-size: 50px;
  }

  .lead {
      font-size: 30px;
      padding-left: 20px;
      padding-right: 20px;
  }

  .formthree {
      padding: 0 10%;
      text-align: center;
  }

  .formthree .form-control {
      font-size: 20px;
  }

  .btnprim {
      margin-left: 0;
      font-size: 30px;
      text-align: center;
      display: block;
      margin: 20px auto;
  }

  form .btn {
      font-size: 25px;
      padding: 15px 40px;
      text-align: center;
      display: block;
      margin: 20px auto;
  }

  .ptb-100 {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 0;
  }
  .placeholder {
    font-size: 40px !important;
  }
  .form-group label {
    font-size: 14px; /* Adjusts label font size */
    color: #666; /* Sets the label text color */
    margin-bottom: 5px; /* Adds space below the label */
    display: block; /* Makes the label a block element */
}
}
