
@media screen and (min-width: 1000px) {
   
    .logo {
        width: 250px;
        border: 2px solid gold;
        border-radius: 50%;
        background-color: gold;
        margin-left: 80px;
    }
    .logo_para {
        font-size: 15px;
        font-weight: 700;
        color: white;
        padding-top: 10px;
        text-align: center;
    }
    
    .navbar .container-fluid {
        flex-direction: column;
    }
    .navbar .navbar-nav .nav-items {
        padding: .5em 1rem;
    }
    .navbar .navbar-nav .nav-link {
        color: white;
        font-weight: 700;
        font-size: 1.1rem;
    }
    .navbar .navbar-nav {
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        margin-top: .5em;
    }
    .navbar-toggler {
        background-color: transparent;
    }
}
@media screen and (max-width: 999px) {
 
    .logo {
        width: 170px;
    }
    .logo_para {
        font-size: 20px;
        font-weight: 900;
        color: rgb(88, 10, 161);
        padding-top: 10px;
        text-align: center;
    }
    .navbar .navbar-nav .nav-link {
        color: transparent;
        background-color: aliceblue;
        color: black;
        font-weight: 700;
        font-size: 40px;
        padding: 40px;
        text-align: center;
        z-index: 1000;
    }
    .navbar .navbar-nav {
        
        margin-top: .5em;
    }
    .navbar-toggler {
        font-size: 60px;
        color: blueviolet;
        background-color: white;
    }
}

