
@import url('https://fonts.cdnfonts.com/css/butter-haunted');
@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.cdnfonts.com/css/great-saturday');


@media screen and (min-width: 1000px) {
    .hero{
        margin-top: -10%;
        background: url('../../IMG/mental2.png');
        background-repeat: no-repeat;
        background-size: cover;
        
    }
    .hero_color {
        background-color: rgb(88, 10, 161, 0.8);
        background-size: cover;
    }
    .ment {
        margin-left: 25%;
    }
    .Hero_p{
        color: white;
        padding-top: 100px;
        margin-top: 100px;
        font-size: 50px;
        font-family: 'Poppins', sans-serif;                                                                      
        text-align: center;
        padding-top: 100px;
    }
    .heroe_para {
          margin-top: 100px;
    }
    .butt {
        margin-bottom: -16%;
    }
    .but1 {
        background-color: rgb(245, 212, 23);
        width: 300px;
        font-size:  20px;
        font-weight: 900;
        padding: 20px;
        color: white;
        border: none;
        border-radius: 50%;
        margin: 10px;
        font-family: 'Poppins', sans-serif;  
        
    }
    .but2 {
        background-color: white;
        width: 300px;
        font-size:  20px;
        font-weight: 900;
        padding: 20px;
        color: rgb(88, 10, 161, 0.7);
        border: none;
        border-radius: 20px;
        margin: 10px;
        font-family: 'Poppins', sans-serif;  
        
    }
    .coma {
        font-size: 70px;
        color: gold;
    }
    .sechero {
        background-color: rgb(88, 10, 161);
        margin-top: 50px;
    }
    .secheroquote {
        color: white;
        padding-top: 100px;
        font-size: 30px;
        font-weight: 800;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    .secherowriter {
        font-size: 50px;
        text-align: center;
        font-family: 'Great Saturday', sans-serif;
        color: gold;
        padding-bottom: 40px;
                                                
    }
}

@media screen and (max-width: 1000px) {
    .hero {
        margin-top: 0%;
        background: url('../../IMG/mental2.png');
        -moz-background-size: cover;
        -webkit-background-size: cover;
        -chrome-background-size: cover;
    }

    .hero_color {
        background-color: rgb(88, 10, 161, 0.7);
        background-size: cover;
    }

    .Hero_p {
        color: white;
        padding-top: 100px;
        margin-top: 100px;
        font-size: 60px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        padding-top: 100px;
    }

    .heroe_para {
        padding-top: 50px;
        margin-left: 15%;
    }

    .butt {
        display: flex;
        justify-content: center; /* Center buttons horizontally */
        align-items: center; /* Center buttons vertically */
        flex-direction: column; /* Stack buttons vertically */
        margin-bottom: -10%;
    }

    .but1, .but2 {
        width: 300px;
        font-size: 40px;
        font-weight: 900;
        padding: 20px;
        border: none;
        margin: 10px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
    }

    .but1 {
        background-color: rgb(245, 212, 23);
        color: white;
    }

    .but2 {
        background-color: white;
        color: rgba(81, 10, 147);
        border-radius: 20px;
    }

    .sechero {
        background-color: rgba(81, 10, 147);
        margin-top: 50px;
    }

    .secheroquote {
        color: white;
        padding-top: 100px;
        font-size: 30px;
        font-weight: 800;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .coma {
        font-size: 70px;
        color: gold;
    }

    .secherowriter {
        font-size: 50px;
        text-align: center;
        font-family: 'Great Saturday', sans-serif;
        color: gold;
        padding-bottom: 40px;
    }
}